.info {
    padding: 0px 10px;
    margin-top: 15px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    border-radius: 5px;
}

.legend {
    text-align: left;
    line-height: 25px;
    color: #555;
}

.legend-item {
    margin-top: -5px;
    padding: 3px 10px;
    border-radius: 3px;
}