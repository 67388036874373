body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.cover {
  /* display: block;
  margin-left: 0;
  margin-right: 0; */
  width: 100%;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.header {
  width: 100%;
  display: block;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: rgba(0, 157, 72, 0.2);
}
.farm {
  float: right;
}
.name {
  float: left;
}
.reportList {
  width: 230mm; /*297x230*/
  /* height: 100mm; */
  padding: 5mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.page {
  width: 297mm; /*297x230*/
  height: 200mm;
  padding: 5mm;
  margin: 10mm auto;
  /* border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.generateReportPage {
  width: 297mm;
  padding: 5mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.reportpage {
  width: 297mm; /* swapped to landscape */
  min-height: 200mm;
  padding: 5mm;
  margin: 10mm auto;
  background: white;
}
.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 257mm;
  outline: 2cm #ffeaea solid;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 297mm;
    height: 210mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
